<!-- DESKTOP -->
<div *ngIf="!MobileService.isMobile" class="desktop footer" fxLayout="column" fxLayoutGap="60px">
  <!-- First Row: Logo/Description, Links, and Social Media -->
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="40px" fxLayout.xs="column">
    <!-- Left Column: Logo and Description -->
    <div class="footer-logo" fxFlex="18" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="start end">
      <div class="logo unselectable"></div>
    </div>

    <!-- Middle Left Column: Community Links -->
    <div fxFlex="25" fxFlex.xs="100">
      <!-- desktop logged out footer description -->
      <div *ngIf="!UserService.isLoggedIn">
        <p class="top-footer-description">
          Profitiere auch du vom großen Netzwerk im Zimmerer-Treffpunkt. Registriere dich jetzt kostenlos. Als Zimmerer,
          Zimmerin oder Dachdecker, Dachdeckerin gehörst du dazu!
        </p>

        <button class="blue-button mobileGrowBtn footer-button" appLoginClickGuard>
          Jetzt registrieren
        </button>
      </div>

      <!-- desktop logged in footer description -->
      <div *ngIf="UserService.isLoggedIn">
        <p class="top-footer-description">
          Poste Blog-Beiträge, biete oder finde über eine Anzeige Zimmereibedarf, inseriere ein Stellenangebot oder ein
          Stellengesuch.
        </p>

        <div fxLayout="row" fxLayoutGap="10px">
          <div style="position: relative;">
            <!-- TODO move to own reusable component / then use in header as well -->
            <button class="orange-button create-btn footer-button"
              (click)="isCreateTooltipVisible ? FooterService.hideCreateTooltipNow() : FooterService.showCreateTooltipNow()"
              (clickOutside)="FooterService.hideCreateTooltipNow()">
              Beitrag erstellen
            </button>

            <!-- global create button tooltip -->
            <div *ngIf="isCreateTooltipVisible" class="createTooltip">

              <div class="entry" (appLoginClickGuard)="StateService.goto('/blog/create',{},true,true)">
                <i class="fas fa-list-alt"></i>
                <div>Blog-Beitrag erstellen</div>
              </div>

              <div class="entry" (appLoginClickGuard)="StateService.goto(
                '/blog/create',
                {preselected:{category:CategoriesService.eventcat}},
                true,
                true
              )">
                <i class="far fa-calendar-check"></i>
                <div>Veranstaltung erstellen</div>
              </div>

              <div class="entry" (appLoginClickGuard)="StateService.goto(
                '/blog/create',
                {preselected:{category:CategoriesService.videocat}},
                true,
                true
              )">
                <i class="fas fa-video"></i>
                <div>Video veröffentlichen</div>
              </div>

              <div class="entry" (appLoginClickGuard)="StateService.goto('/market/create',{},true,true)">
                <i class="fa fa-euro-sign"></i>
                <div>Zimmereibedarf verkaufen</div>
              </div>

              <div class="entry" (appLoginClickGuard)="StateService.goto(
                '/market/create',
                {preselected:{advertisement_type:'SEARCH'}},
                true,
                true
              )">
                <i class="fas fa-shopping-cart"></i>
                <div>Zimmereibedarf suchen</div>
              </div>

              <div class="entry" (appLoginClickGuard)="StateService.goto('/job/create',{},true,true)">
                <i class="fas fa-user-plus"></i>
                <div>Stellenanzeige posten</div>
              </div>

              <div class="entry" (appLoginClickGuard)="StateService.goto('/job/create',{},true,true)">
                <i class="fas fa-search-plus"></i>
                <div>Stellengesuch posten</div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <p class="bottom-footer-description">
        Posten, kommentieren, aktiv werden und Woodcoins sammeln! Räume tolle Preise ab!
      </p>

      <div class="bonus-system-link" (click)="goto('/boni/faq')">
        Mehr zum Bonussystem
      </div>
    </div>

    <!-- Middle Right Column: More Links -->
    <div fxFlex="10" fxLayout="column" fxLayoutGap="10px">
      <h4 class="footer-links-heading"></h4>

      <div class="footer-links">
        <div class="footer-links-items-container" fxLayout="column" fxLayoutGap="15px">
          <div class="footer-links-item" (click)="goto('/home')">Deine Vorteile</div>
          <div class="footer-links-item" (click)="goto('/profile/business')">Unternehmen</div>
          <div class="footer-links-item" (click)="goto('/peripherals/about')">Über uns</div>
          <div class="footer-links-item" (click)="goto('/peripherals/career')">Karriere</div>
          <div class="footer-links-item" (click)="goto('/peripherals/contact')">Kontakt</div>
        </div>
      </div>
    </div>

    <!-- Right Column: Social Media Icons -->
    <div fxFlex="25" fxFlex.xs="100" class="social-media-icons">
      <h4 class="social-media-icons-heading">Folge uns!</h4>

      <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start start">
        <div *ngFor="let socialMediaNumber of socialMediaNumbers">
          <a href="{{socialMediaNumber.link}}" target="_blank">
            <div *ngIf="socialMediaNumber.who == 'Mitglieder'">
              <!-- 
                TODO use real icon from assets not from the build
                somehow the icon file is not included in the build if not called somewhere i.e. in footer.component.css 
              -->
              <img src="build/assets/images/logo.svg" width="16" height="16">
              <span class="social-media-icon-text">
                {{socialMediaNumber.number}} {{socialMediaNumber.who}}
              </span>
            </div>

            <div *ngIf="socialMediaNumber.who == 'Facebook-Mitgliedergruppe'">
              <img src="build/assets/images/users_group.svg" width="16" height="16">
              <span class="social-media-icon-text">
                {{socialMediaNumber.who}}
              </span>
            </div>

            <div *ngIf="socialMediaNumber.who != 'Mitglieder' && socialMediaNumber.who != 'Facebook-Mitgliedergruppe'">
              <i [class]="socialMediaNumber.icon"></i>
              <span class="social-media-icon-text">
                {{socialMediaNumber.number}} {{socialMediaNumber.who}}
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Second Row: Imprint Links and Copyright -->
  <div class="footer-bottom" fxLayout="row" fxLayoutAlign="space-between end">
    <!-- Left Side: Imprint Links -->
    <div fxFlex="25" fxFlex.xs="100" class="imprint-links-container">
      <div class="imprint-links" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
        <div class="imprint-links-item" (click)="goto('/peripherals/agb')">AGB</div>
        <div class="imprint-links-item" (click)="goto('/peripherals/privacy')">Datenschutz</div>
        <div class="imprint-links-item" (click)="goto('/peripherals/impressum')">Impressum</div>
      </div>
    </div>

    <!-- Right Side: Copyright -->
    <div fxFlex="25" fxLayoutAlign="end center">
      <div class="copyright">
        <div>&copy; {{getCurrentYear()}} Zimmerer-Treffpunkt</div>
      </div>
    </div>
  </div>

</div>

<!-- MOBILE -->
<div *ngIf="MobileService.isMobile" class="mobile footer threecol">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayout.xs="column">
    <div class="footer-logo" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="center center">
      <div class="logo unselectable"></div>
    </div>

    <div fxFlex="25" fxFlex.xs="100" fxLayoutAlign="center center" fxLayout="column">
      <div *ngIf="!UserService.isLoggedIn">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
          <p class="top-footer-description">
            Profitiere auch du vom großen Netzwerk im Zimmerer-Treffpunkt. Registriere dich jetzt kostenlos. Als
            Zimmerer,
            Zimmerin oder Dachdecker, Dachdeckerin gehörst du dazu!
          </p>

          <button class="blue-button mobileGrowBtn" appLoginClickGuard>
            Jetzt registrieren
          </button>
        </div>
      </div>

      <div *ngIf="UserService.isLoggedIn">
        <div fxLayout="column" fxLayoutAlign="center center">
          <p class="top-footer-description">
            Poste Blog-Beiträge, biete oder finde über eine Anzeige Zimmereibedarf, inseriere ein Stellenangebot oder
            ein
            Stellengesuch.
          </p>
        </div>

        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div>
            <!-- TODO reuse the create-panel component if possible -->
            <mat-expansion-panel class="orange-panel footer-mat-expansion-panel">
              <mat-expansion-panel-header class="footer-mat-expansion-panel-header">
                <mat-panel-title>
                  Beitrag erstellen
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="entry" (click)="StateService.goto('/market/create',{},true,true)">
                <i class="fa fa-euro-sign"></i>
                <div>Zimmereibedarf verkaufen</div>
              </div>
              <div class="entry" (click)="StateService.goto('/market/create',{},true,true)">
                <i class="fas fa-shopping-cart"></i>
                <div>Zimmereibedarf suchen</div>
              </div>
              <div class="entry" (click)="StateService.goto('/job/create',{},true,true)">
                <i class="fas fa-user-plus"></i>
                <div>Stellenanzeige posten</div>
              </div>
              <div class="entry" (click)="StateService.goto('/job/create',{},true,true)">
                <i class="fas fa-search-plus"></i>
                <div>Stellengesuch posten</div>
              </div>
              <div class="entry" (click)="StateService.goto('/blog/create',{},true,true)">
                <i class="fas fa-list-alt"></i>
                <div>Fachbeitrag erstellen</div>
              </div>
              <div class="entry" (click)="StateService.goto(
              '/blog/create',
              {preselected:{category:CategoriesService.eventcat}},
              true,
              true
            )">
                <i class="far fa-calendar-check"></i>
                <div>Veranstaltung erstellen</div>
              </div>
              <div class="entry"
                (click)="StateService.goto('/blog/create',{preselected:{category:CategoriesService.videocat}},true,true)">
                <i class="fas fa-video"></i>
                <div>Video veröffentlichen</div>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>

      <p class="bottom-footer-description">
        Posten, kommentieren, aktiv werden und Woodcoins sammeln! Räume tolle Preise ab!
      </p>

      <div class="bonus-system-link" (click)="goto('/boni/faq')">
        Mehr zum Bonussystem
      </div>
    </div>

    <hr class="footer-divider">

    <div fxFlex="25" fxFlex.xs="100" class="social-media-icons" fxLayoutAlign="center center" fxLayout="column"
      fxLayoutGap="20px">
      <h4 class="social-media-icons-heading">Folge uns!</h4>

      <div *ngFor="let socialMediaNumber of socialMediaNumbers">
        <div *ngIf="socialMediaNumber.who == 'Mitglieder'">
          <!-- 
            TODO use real icon from assets not from the build
            somehow the icon file is not included in the build if not called somewhere i.e. in footer.component.css 
          -->
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
            <img src="build/assets/images/logo.svg" width="64" height="64">
            <span class="social-media-icon-text">
              {{socialMediaNumber.number}} {{socialMediaNumber.who}}
            </span>
          </div>
        </div>

        <div *ngIf="socialMediaNumber.who == 'Facebook-Mitgliedergruppe'">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
            <img src="build/assets/images/users_group.svg" width="64" height="64">
            <span class="social-media-icon-text">
              {{socialMediaNumber.who}}
            </span>
          </div>
        </div>

        <div *ngIf="socialMediaNumber.who != 'Mitglieder' && socialMediaNumber.who != 'Facebook-Mitgliedergruppe'">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
            <i [class]="socialMediaNumber.mobileIcon"></i>
            <span class="social-media-icon-text">
              {{socialMediaNumber.number}} {{socialMediaNumber.who}}
            </span>
          </div>
        </div>
      </div>
    </div>

    <hr class="footer-divider">

    <div fxFlex="10" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
      <h4 class="footer-links-heading"></h4>

      <div class="footer-links">
        <div class="footer-links-items-container" fxLayout="column" fxLayoutGap="30px" fxLayoutAlign="center center">
          <div class="footer-links-item" (click)="goto('/home')">Deine Vorteile</div>
          <div class="footer-links-item" (click)="goto('/profile/business')">Unternehmen</div>
          <div class="footer-links-item" (click)="goto('/peripherals/about')">Über uns</div>
          <div class="footer-links-item" (click)="goto('/peripherals/career')">Karriere</div>
          <div class="footer-links-item" (click)="goto('/peripherals/contact')">Kontakt</div>
        </div>
      </div>
    </div>
  </div>

  <hr class="footer-divider">

  <div class="footer-bottom" fxLayout="column" fxLayout.xs="column">
    <div fxFlex="60" fxFlex.xs="100">
      <div class="imprint-links" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
        <div class="imprint-links-item" (click)="goto('/peripherals/agb')">AGB</div>
        <div class="imprint-links-item" (click)="goto('/peripherals/privacy')">Datenschutz</div>
        <div class="imprint-links-item" (click)="goto('/peripherals/impressum')">Impressum</div>
      </div>
    </div>

    <div fxFlex="50" fxFlex.xs="100" fxLayoutAlign="center center">
      <div class="copyright">&copy; {{getCurrentYear()}} Zimmerer-Treffpunkt</div>
    </div>
  </div>
</div>