<div class="image-slider-bar" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="image-slider-bar-left-column" fxLayout="row" fxFlex="66" fxLayoutAlign="space-evenly center"
        fxLayoutGap="70px">
        <div (click)="goto('/home')" class="image-slider-bar-icon">
            <div fxLayout="row" style="padding-left: 40px;">
                <img src="/build/assets/images/star.svg" alt="Advantages Icon" width="32" height="32">
                <span class="image-slider-bar-icon-text">
                    Deine Vorteile
                </span>
            </div>
        </div>

        <div (click)="goto('/boni/faq')" class="image-slider-bar-icon">
            <div fxLayout="row">
                <img src="/build/assets/images/woodcoin.svg" alt="Woodcoins Icon" width="32" height="32">
                <span class="image-slider-bar-icon-text">
                    Unser Bonussystem
                </span>
            </div>
        </div>

        <a class="image-slider-bar-icon" href="https://www.facebook.com/groups/725765151265175/" target="_blank">
            <div fxLayout="row">
                <img src="/build/assets/images/users_group.svg" alt="Facebook Group Icon" width="32" height="32">
                <span class="image-slider-bar-icon-text">
                    Facebook-Mitgliedergruppe
                </span>
            </div>
        </a>
    </div>

    <div class="image-slider-bar-right-column" fxLayout="row" fxFlex="20" fxLayoutAlign="center">
        <create-entry-button></create-entry-button>
    </div>

</div>