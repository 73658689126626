import { Component, OnInit, Input, NgModule } from '@angular/core';
import { state, style, transition, animate, trigger } from '@angular/animations';
import { SliderImagesService } from '@service/slider-images.service';
import { Sliderimage } from '@model/sliderimage';
import { CacheService } from '@service/cache.service';
import { environment } from '@environments/environment';
import { SharedModule } from 'src/app/shared.module';
import { ImageSliderBarComponentModule } from '../image-slider-bar/image-slider-bar.module';
import { MobileService } from '@service/mobile.service';

@Component({
  selector: 'image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
  animations: [
    trigger('openClose', [
      state('start', style({
        width: '0%'
      })),
      state('end', style({
        width: '100%'
      })),
      transition('start => end', [
        animate('10s')
      ]),
      transition('end => start', [
        animate('0s')
      ]),
    ])
  ],
  host: {
      '[style.padding-bottom]': `sliderItems?.length > 0 ? '0px' : no_image_margin`,
  },
})
export class ImageSliderComponent implements OnInit {

  public environment = environment

  @Input()
  public page: String = null

  @Input()
  public hasImageSliderBar: boolean = false

  @Input('no-image-margin')
  public no_image_margin: string = '0px';

  public selected_index = null

  // autoswitch
  public progressBarValue
  public intervalTime = 15 // in s
  public secs = 0
  private interval
  private timer

  public sliderItems: Sliderimage[] = null

  constructor(
    private SliderImagesService: SliderImagesService,
    private CacheService: CacheService,
    public MobileService: MobileService
  ) { }

  ngOnInit() {
    const cacheResult = this.CacheService.getByIndex(this.page)

    if (cacheResult) {
      this.sliderItems = cacheResult.images
      this.SliderImagesService.hasSliderImages = cacheResult.hasSliderImages
    } else {
      this.SliderImagesService.getByPage(this.page)
        .then((response) => {
          this.sliderItems = response.images
          this.SliderImagesService.hasSliderImages = response.hasSliderImages
          this.CacheService.setIndex(this.page, response)
        })
    }

    this.start_interval()
  }

  public start_interval() {
    this.interval = setInterval(() => {
      this.next()
    }, this.intervalTime * 1000)

    this.secs = 1
    this.timer = setInterval(() => {
      this.secs++
    }, 1000)
  }

  private resetInterval() {
    clearInterval(this.interval)
    this.start_interval()
  }

  public next() {
    // check if not null maybe
    if (this.selected_index + 2 > this.sliderItems.length) {
      this.selected_index = 0
    }
    else {
      this.selected_index++
    }
    this.secs = 0
  }

  public previous() {

    this.selected_index--
    if (this.selected_index < 0)
      this.selected_index = this.sliderItems.length - 1

    this.secs = 0
  }

  public get current() {
    if (!this.sliderItems) {
      return
    }

    if (this.selected_index != null && this.selected_index > -1 && this.selected_index < this.sliderItems.length &&
      typeof this.sliderItems[this.selected_index] != "undefined") {
      return this.sliderItems[this.selected_index]
    }

    else {
      return this.sliderItems[0]
    }
  }

  public get getHasImageSliderBar() {
    return this.hasImageSliderBar;
  }
}

@NgModule({
  declarations: [
    ImageSliderComponent
  ],
  imports: [
    SharedModule,
    ImageSliderBarComponentModule
  ],
  exports: [
    ImageSliderComponent,
    SharedModule
  ]
})
export class ImageSliderComponentModule { }
