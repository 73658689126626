import { Component, Input, OnInit } from '@angular/core'
import { environment } from '@environments/environment'
import { CategoriesService } from '@service/categories.service'
import { CreateEntryButtonComponentService } from '@service/create-entry-button.service'
import { StateService } from '@service/state.service'

@Component({
    selector: 'create-entry-button',
    templateUrl: './create-entry-button.component.html',
    styleUrls: ['./create-entry-button.component.scss']
})
export class CreateEntryButtonComponent implements OnInit {

    public environment = environment

    @Input()
    public hasPlusIcon: boolean = false


    constructor(
        public StateService: StateService,
        public CategoriesService: CategoriesService,
        public CreateEntryButtonComponentService: CreateEntryButtonComponentService
    ) { }

    ngOnInit(): void {
        this.StateService.stateDataChanged.subscribe(() => {
            this.CreateEntryButtonComponentService.hideCreateTooltipNow()
        })
    }

    get isCreateTooltipVisible(): boolean {
        return this.CreateEntryButtonComponentService.showCreateTooltip
    }

    public handleShowCreateTooltipEvent(event: Event) {
        // important, otherwise the hideCreateTooltipNow trigged in parallel presumably due too multiple create tooltips on the same component
        event.stopPropagation();

        if (this.isCreateTooltipVisible) {
            this.CreateEntryButtonComponentService.hideCreateTooltipNow();
        } else {
            this.CreateEntryButtonComponentService.showCreateTooltipNow();
        }
    }

    get getHasPlusIcon(): boolean {
        return this.hasPlusIcon
    }
}