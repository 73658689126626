import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared.module";
import { CreateEntryButtonComponent } from "./create-entry-button.component";
import { CreateEntryButtonComponentService } from "@service/create-entry-button.service";

@NgModule({
    declarations: [
        CreateEntryButtonComponent
    ],
    providers: [
        CreateEntryButtonComponentService
    ],
    imports: [
        SharedModule
    ],
    exports: [
        CreateEntryButtonComponent,
    ]
})
export class CreateEntryButtonComponentModule { }