export const appConfig = {
  credits: (<any>window).credits,
  maxSizeImage: 10485760, // bytes = 10 MB

  shareconfig_mobile: ['facebook', 'messenger', 'whatsapp'],
  shareconfig_desktop: ['facebook', 'whatsapp', 'email'],
  sharebuttonsmax: 4,
  // ['facebook', 'twitter', 'email', 'telegram', 'messenger', 'whatsapp', 'line', 'google']

  members: "15.000",
  members_facebook: "26.000",
  members_instagram: "32.000",
  members_linkedin: "1.500",
  members_facebook_group: ""
}
